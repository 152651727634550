import { getData } from './request.js'

/**
 * 查询任务
 */
export function getMissionList(data) {
    return getData({
        url: 'mapi/mission/list',
        method: 'get',
    })
}

/**
 * 查询记录
 */
export function getMissionRecord(data) {
    return getData({
        url: 'mapi/mission/record',
        method: 'get',
        data: data
    })
}

/**
 * 运行任务
 */
export function runMission(data) {
    return getData({
        url: 'mapi/mission/run',
        method: 'post',
        data: data
    })
}


/**
 * 新建任务
 */
export function addMission(data) {
    return getData({
        url: 'mapi/mission/add',
        method: 'post',
        data: data
    })
}

/**
 * 修改任务
 */
export function updateMission(data) {
    return getData({
        url: 'mapi/mission/update',
        method: 'post',
        data: data
    })
}

/**
 * 删除任务
 */
export function deleteMission(data) {
    return getData({
        url: 'mapi/mission/delete',
        method: 'delete',
        data: data
    })
}
