<template>
  <div class="container">
    <el-form :loading="loading" style="display: flex;flex-direction: row;" ref="form" :model="preFrom" label-width="80px">
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新建任务
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-refresh-right"
            size="mini"
            @click="getMissionDataList"
        >刷新
        </el-button>
      </el-col>
    </el-row>
    <el-table border stripe :loading="loading" :data="missionDataList" style="margin-top: 5px;">
      <el-table-column label="#" align="center" type="index"/>
      <el-table-column label="任务编号" align="center" prop="id"/>
      <el-table-column label="任务名称" align="center" prop="missionName"/>
      <el-table-column label="描述" align="center" prop="description"/>
      <el-table-column label="任务流程" align="center" prop="actions" width="200px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-view" @click="toggleDetails(scope.row.actions)">查看详情</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" prop="exporterName" width="140px">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-video-play"
              @click="handleRun(scope.row)"
          >运行
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-document"
              @click="handleRecord(scope.row.id)"
          >记录
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看详情弹窗 -->
    <el-dialog :visible.sync="showDetails.open" title="任务流程" width="30%">
      <div v-for="(action, index) in showDetails.data" :key="index" style="margin-bottom: 10px">
        <el-button type="text" size="mini" @click="actionDetail(action.missionName, action, index)">
          <el-tag>{{ index + 1 }}.{{ getActionType(action.actionType) }}</el-tag>
        </el-button>
      </div>
    </el-dialog>
    <!-- 任务流程详情 -->
    <el-dialog :title="actionData.title" :visible.sync="actionData.open" width="30%" append-to-body>
      <el-form v-loading="loading" :data="actionData.data" label-position="left" inline
               style="display: flex;flex-direction: column;" label-width="100px">
        <el-form-item label="行为类型">
          <span>{{ getActionType(actionData.data.actionType) }}</span>
        </el-form-item>
        <el-form-item label="所属任务ID">
          <span>{{ actionData.data.missionId }}</span>
        </el-form-item>
        <el-form-item label="备注">
          <span>{{ actionData.data.comment }}</span>
        </el-form-item>
        <el-form-item label="账户ID">
          <div v-for="(item, index) in actionData.data.advertiser_ids" :key="index">
            <span>{{item.advertiser_id}}</span>
          </div>
        </el-form-item>
        <el-form-item label="聚星任务ID">
          <div v-for="(item, index) in actionData.data.task_ids" :key="index">
            <span>{{ item.task_id === null ? '无' : item.task_id }}</span>
          </div>
        </el-form-item>
        <el-form-item label="日期形式">
          <span>{{ getDateType(actionData.data.dateType) }}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建任务 -->
    <el-dialog :title="addMission.title" :visible.sync="addMission.open" width="40%" append-to-body :close-on-click-modal=false>
      <el-form v-loading="loading" :rules="rules" :model="preFrom" ref="ruleForm" label-position="left" inline
               style="display: flex;flex-direction: column;padding: 20px;" label-width="100px">
        <el-form-item label="任务名称" prop="missionName">
          <el-input v-model="preFrom.missionName" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="任务描述" prop="description">
          <el-input v-model="preFrom.description" placeholder="请输入任务描述"></el-input>
        </el-form-item>
        <el-card style="margin-bottom: 10px"
                 v-for="(action, index) in preFrom.actions"
                 :key="index">
          <div slot="header" class="clearfix">
            <span>任务行为{{ index + 1 }}</span>
            <el-button style="float: right; padding: 3px 0;color: #ff4949" type="text" icon="el-icon-delete" @click.prevent="removeAction(action)">删除</el-button>
          </div>
          <div style="display:flex;flex-direction: column;">
            <el-form-item label="行为类型" :rules="itemRules" :prop="'actions.'+index+'.actionType'">
              <el-select v-model="action.actionType" placeholder="请选择行为类型">
                <el-option
                    v-for="item in actionType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="action.comment" placeholder="请输入行为备注"></el-input>
            </el-form-item>
            <el-form-item label="账户ID" :prop="'actions.'+index+'.advertiserId'">
              <div style="display: flex;align-items: center" v-for="(item, index) in action.advertiser_ids" :key="index">
                <el-input v-model.number="item.advertiser_id"  placeholder="请输入账户ID" style="margin-bottom: 5px"/>
                <el-button v-if="index===0" style="height: 20px;width: 20px ;padding: 0;border: none;color: #66c03a" icon="el-icon-circle-plus-outline" @click.prevent="addAccount(action)"></el-button>
                <el-button v-if="index!==0" style="height: 20px;width: 20px ;padding: 0;border: none;color: #ff4949" icon="el-icon-remove-outline" @click.prevent="removeAccount(action,index)"></el-button>
              </div>
            </el-form-item>
            <el-form-item label="日期">
              <el-select v-model="action.dateType" placeholder="请选择日期">
                <el-option
                    v-for="item in dateType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="聚星任务ID">
              <div v-for="(item, index) in action.task_ids" :key="index">
                <el-input v-model="item.task_id" type="textarea" placeholder="请输入聚星任务ID" style="margin-bottom: 5px"/>
              </div>
            </el-form-item>
          </div>
        </el-card>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="addAction">新增任务行为</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 运行任务的 el-dialog -->
    <el-dialog :visible.sync="runMissionVisible.open" :title=runMissionVisible.title width="35%" append-to-body>
      <el-form v-loading="loading" :model="runMissionVisible.data" ref="ruleForm" label-position="left" inline
               style="display: flex;flex-direction: column;padding: 20px;" label-width="100px">
        <el-form-item label="任务编号">
          <span>{{runMissionVisible.data.id}}</span>
        </el-form-item>
        <el-form-item label="任务名称">
          <span>{{runMissionVisible.data.missionName}}</span>
        </el-form-item>
        <el-form-item label="任务描述">
          <span>{{runMissionVisible.data.description}}</span>
        </el-form-item>
        <el-card style="margin-bottom: 10px"
                 v-for="(action, index) in runMissionVisible.data.actions"
                 :key="index">
          <div slot="header" class="clearfix">
            <span>任务行为{{ index + 1 }}</span>
          </div>
          <div style="display:flex;flex-direction: column;">
            <el-form-item label="行为类型">
              <span>{{getActionType(action.actionType)}}</span>
            </el-form-item>
            <el-form-item label="账户ID">
              <div v-for="(item, index) in action.advertiser_ids" :key="index">
                <span>{{item.advertiser_id}}</span>
              </div>
            </el-form-item>
            <el-form-item label="备注">
              <span>{{action.comment}}</span>
            </el-form-item>
            <el-form-item label="日期">
              <span>{{getDateType(action.dateType)}}</span>
            </el-form-item>
            <el-form-item label="聚星任务ID">
              <div v-for="(item, index) in action.task_ids" :key="index">
                <span>{{ item.task_id === '' ? '无' : item.task_id }}</span>
              </div>
            </el-form-item>
          </div>
        </el-card>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="onSubmitMissionRun(runMissionVisible.data)">运行任务</el-button>
        <el-button @click="runMissionVisible.open = false">取消</el-button>
    </span>
    </el-dialog>
    <!-- 修改任务的 el-dialog -->
    <el-dialog :visible.sync="updateMissionVisible.open" :title=updateMissionVisible.title width="35%" append-to-body :close-on-click-modal=false>
      <el-form v-loading="loading" :model="updateMissionVisible.data" ref="ruleForm" label-position="left" inline
               style="display: flex;flex-direction: column;padding: 20px;" label-width="100px">
        <el-form-item label="任务编号">
          <span>{{updateMissionVisible.data.id}}</span>
        </el-form-item>
        <el-form-item label="任务名称">
          <el-input v-model="updateMissionVisible.data.missionName" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="任务描述">
          <el-input v-model="updateMissionVisible.data.description" placeholder="请输入任务描述"></el-input>
        </el-form-item>
        <el-card style="margin-bottom: 10px"
                 v-for="(action, index) in updateMissionVisible.data.actions"
                 :key="index">
          <div slot="header" class="clearfix">
            <span>任务行为{{ index + 1 }}</span>
            <el-button style="float: right; padding: 3px 0;color: #ff4949" type="text" icon="el-icon-delete" @click.prevent="updateRemoveAction(action)">删除</el-button>
          </div>
          <div style="display:flex;flex-direction: column;">
            <el-form-item label="行为类型">
              <el-select v-model="action.actionType" placeholder="请选择行为类型">
                <el-option
                    v-for="item in actionType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="action.comment" placeholder="请输入行为备注"></el-input>
            </el-form-item>
            <el-form-item label="账户ID">
              <div style="display: flex;align-items: center;" v-for="(item, index) in action.advertiser_ids" :key="index">
                <el-input v-model.number="item.advertiser_id"  placeholder="请输入账户ID" style="margin-bottom: 5px;margin-right: 5px;"/>
                <el-button v-if="index===0" style="height: 20px;width: 20px ;padding: 0;border: none;color: #66c03a" icon="el-icon-circle-plus-outline" @click.prevent="addAccount(action)"></el-button>
                <el-button v-if="index!==0" style="height: 20px;width: 20px ;padding: 0;border: none;color: #ff4949" icon="el-icon-remove-outline" @click.prevent="removeAccount(action,index)"></el-button>
              </div>
            </el-form-item>
            <el-form-item label="日期">
              <el-select v-model="action.dateType" placeholder="请选择日期">
                <el-option
                    v-for="item in dateType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="聚星任务ID">
              <div v-for="(item, index) in action.task_ids" :key="index">
                <el-input v-model="item.task_id" type="textarea" placeholder="请输入聚星任务ID" style="margin-bottom: 5px"/>
              </div>
            </el-form-item>
          </div>
        </el-card>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="updateSubmit">确认</el-button>
        <el-button @click="updateAddMission">新增任务行为</el-button>
        <el-button @click="updateMissionVisible.open = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="MissionRecord.open" :title=MissionRecord.title width="35%" append-to-body :close-on-click-modal=false>
      <div class="radio" style=" margin-bottom: 25px;font-size: 16px;font-weight: bolder;color: #000">
        排序：
        <el-radio-group v-model="reverse">
          <el-radio :label="true">倒序</el-radio>
          <el-radio :label="false">正序</el-radio>
        </el-radio-group>
      </div>
      <el-timeline :reverse="reverse">
        <el-timeline-item
            v-for="(item, index) in MissionRecord.data"
            :key="index"
            :timestamp="parseTime(item.operateTime)">
          {{getActionString(item.action)}}{{item.result}}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <el-dialog :visible.sync="showSuccessResult" >
      <el-result   icon="success" title="成功" subTitle="任务已开始执行">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="showSuccessResult = false">返回</el-button>
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>

<script>
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'
import {parseTime} from "@/utils/untilsMethod";
import {addMission, deleteMission, getMissionList, getMissionRecord, runMission, updateMission} from "@/api/mission";
import { cloneDeep } from 'lodash';

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next);
  },
  data() {
    return {
      loading: false,
      reverse: true,
      showSuccessResult: false,
      showDetails:{
        open:false,
        data: [],
      },
      runMissionVisible: {
        open: false,
        title: '',
        data: {}
      },
      updateMissionVisible: {
        open: false,
        title: '',
        data: {}
      },
      // 日期类型
      actionType:[
        { id: 1, label: '星视频一体式导出' },
        { id: 2, label: '星任务(视频)一体式导出' },
        { id: 3, label: '星视频助推分时数据' },
        { id: 4, label: '星任务(视频)助推分时数据' },
      ],
      dateType: [
        { id: 1, label: '最近一天' },
        { id: 2, label: '最近一周' },
        { id: 3, label: '最近一个月' },
        { id: 4, label: '最近两个月' },
        { id: 5, label: '最近三个月' },
        { id: 6, label: '最近半年' },
        { id: 7, label: '最近一年' },
      ],
      // 任务数据
      missionDataList: [],
      // 操作记录弹出
      MissionRecord: {
        open: false,
        title: '操作记录',
        data: undefined,
      },
      // 行为数据弹窗
      actionData: {
        open: false,
        title: '',
        data: {}
      },
      // 新建任务弹窗
      addMission: {
        open: false,
        title: '',
      },
      rules: {
        missionName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
      },
      itemRules: [
        { required: true, message: '请选择行为类型', trigger: 'blur' },
      ],
      adIdRules: [
        { required: true, message: '请输入账户ID', trigger: 'blur' },
      ],
      preFrom: {
        missionName: undefined,
        description: undefined,
        actions: [
          {actionType: undefined,comment:undefined, advertiser_ids: [{advertiser_id: undefined}], dateType: undefined, task_ids: [{task_id: ''}]}
        ],
      },
      form: {}
    }
  },
  created() {
    this.getMissionDataList()
  },
  methods: {
    handleUpdate(item) {
      this.updateMissionVisible.title = '修改任务内容';
      this.updateMissionVisible.data = cloneDeep(item);
      this.updateMissionVisible.open = true;
    },
    handleRun(item) {
      this.runMissionVisible.title = '任务详情确认';
      this.runMissionVisible.data = item;
      this.runMissionVisible.open = true;
    },
    handleRecord(item){
      getMissionRecord({id: item}).then(response => {
        this.MissionRecord.data = response.data
        this.MissionRecord.open = true;
      });
    },
    handleDelete(item) {
      this.$confirm("删除后将不可恢复, 是否继续?", "删除任务", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMission({id: item}).then(response => {
          this.$message.success(response.msg)
          this.getMissionDataList();
        })
      });
    },
    parseTime,
    // 查询
    getMissionDataList() {
      this.loading = true;
      getMissionList().then(response => {
        this.missionDataList = response.data
      }).catch(response => {
        this.$message.error(response.msg)
      })
      this.loading = false;
    },
    // 新增
    handleAdd() {
      this.addMission.title = '新建任务';
      this.addMission.open = true;
    },
    toggleDetails(data) {
      this.showDetails.data = data
      this.showDetails.open = !this.showDetails.open; // 切换弹窗显示状态

    },
    // 任务流程详情 弹窗
    actionDetail(missionName, action, index) {
      this.actionData.data = action;
      this.actionData.open = true;
      this.actionData.title = "任务[" + missionName + "]---行为[" + (index + 1) + "]详情数据";
    },
    // 新建任务
        getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="星视频订单一体式导出记录"
      })
    },
    onSubmit() {
      const { missionName, description, actions } = this.preFrom; // 解构赋值
      // 验证任务名称、描述和行为
      if (!this.validateField(missionName, "任务名称不能为空") ||
          !this.validateField(description, "任务描述不能为空") ||
          !this.validateField(actions.length > 0, "至少需要一个任务行为")) {
        return;
      }
      // 判断所有 advertiser_id 是否都不为空
      const allAdvertiserIdsNotEmpty = actions.every(action =>
          action.advertiser_ids.every(advertiser => advertiser.advertiser_id)
      );
      if (!allAdvertiserIdsNotEmpty) {
        this.$message.error("账户ID不能为空");
        return;
      }
      this.loading = true;
      console.log(this.preFrom);
      this.getForm(this.preFrom);
      console.log(this.form);
      addMission(this.form).then(response => {
        this.$message.success(response.msg);
        this.getMissionDataList();
      }).catch(() => {
        this.$message.error("请求错误");
      })
      this.resetForm();
      this.addMission.open = false;
      this.loading = false;
    },
    // 运行任务
    onSubmitMissionRun(data) {
      this.loading = true;
      runMission(data).then(response => {
        if (response.code === 200) {
          this.showSuccessResult = true
          this.$message.success(response.msg)
          this.getMissionDataList();
        }else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.$message.error("请求错误")
      })
      this.runMissionVisible.open = false;
      this.loading = false;
    },
    // 修改任务内容
    updateSubmit(){
      const { missionName, description, actions } = this.updateMissionVisible.data; // 解构赋值
// 验证任务名称、描述和行为
      if (!this.validateField(missionName, "任务名称不能为空") ||
          !this.validateField(description, "任务描述不能为空") ||
          !this.validateField(actions.length > 0, "至少需要一个任务行为")) {
        return;
      }
      // 判断所有 advertiser_id 是否都不为空
      const allAdvertiserIdsNotEmpty = actions.every(action =>
          action.advertiser_ids.every(advertiser => advertiser.advertiser_id)
      );
      if (!allAdvertiserIdsNotEmpty) {
        this.$message.error("账户ID不能为空");
        return;
      }
      this.loading = true;
      this.getForm(this.updateMissionVisible.data);
      updateMission(this.form).then(response =>{
        this.$message.success(response.msg);
        this.updateMissionVisible.open = false;
        this.getMissionDataList();
      }).catch(() => {
        this.$message.error("请求错误");
      })
      this.resetForm();
      this.loading = false;
    },
    // 格式化表单
    getForm(data) {
      this.form = cloneDeep(data);
      if (this.form.actions.length > 0) {
        this.form.actions.forEach(action => {
          if (action.task_ids) {
            action.task_ids.forEach(item =>{
              item.task_id = item.task_id.trim().split(/[,\s]+/).join(',');
            })
          }
        });
      }
    },
    // 获取日期选择器 日期
    getDate(value) {
      if (this.createTime) {
        this.preFrom.create_start_time = this.createTime[0]
        this.preFrom.create_end_time = this.createTime[1]
      }
      if (value == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.create_start_time = ''
        this.preFrom.create_end_time = ''
      }
    },
    //
    // 新增任务行为
    addAction() {
      this.preFrom.actions.push({
        actionType: undefined,
        comment:undefined,
        advertiser_ids: [{advertiser_id: undefined}],
        dateType: undefined,
        task_ids: [{task_id: ''}]
      })
    },
    // 新增修改任务行为
    updateAddMission(){
      this.updateMissionVisible.data.actions.push({
        actionType: undefined,
        comment:undefined,
        advertiser_ids: [{advertiser_id: undefined}],
        dateType: undefined,
        task_ids: [{task_id: ''}]
      });
    },
    // 删除任务行为
    removeAction(item) {
      var index = this.preFrom.actions.indexOf(item)
      if (index !== -1) {
        this.preFrom.actions.splice(index, 1)
      }
    },
    // 删除任务行为
    updateRemoveAction(item) {
      var index = this.updateMissionVisible.data.actions.indexOf(item)
      if (index !== -1) {
        this.updateMissionVisible.data.actions.splice(index, 1)
      }
    },
    addAccount(item){
      // 向数组中添加一个新对象，默认 ad_id 为空字符串
      item.advertiser_ids.push({ advertiser_id: undefined });
      item.task_ids.push({ task_id: '' });
    },
    removeAccount(item,index){
      item.advertiser_ids.splice(index,1)
      item.task_ids.splice(index,1)
    },
    // 重置新增任务列表
    resetForm() {
      this.preFrom = {
        missionName: undefined,
        description: undefined,
        actions: [
          {actionType: undefined,comment:undefined, advertiser_ids: [{advertiser_id: undefined}], dateType: undefined, task_ids: [{ task_id: '' }]}
        ],
      };
      this.form = {};
    },
    getActionString(item){
      return item === 'add' ? '创建任务' : item === 'run' ? '运行任务' : item === 'update' ? '修改任务内容' : '未知操作'
    },
    // 行为类型转换
    getActionType(item) {
      const actionType = {
        1: '星视频一体式导出',
        2: '星任务(视频)一体式导出',
        3: '星视频助推分时数据',
        4: '星任务(视频)助推分时数据',
      };
      return actionType[item];
    },
    // 日期类型转换
    getDateType(dateTpye) {
      const dateTypeMap = {
        1: '最近一天',
        2: '最近一周',
        3: '最近一个月',
        4: '最近两个月',
        5: '最近三个月',
        6: '最近半年',
        7: '最近一年'
      };
      return dateTypeMap[dateTpye] || '未选择日期';
    },
    // 校验函数
    validateField(field, message){
      if (!field) {
        this.$message.error(message);
        return false;
      }
      return true;
    }
  }
}
</script>
<style>
.container {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
